<template>
  <div>
    <banner :path="bannerPath"></banner>
    <my-tabs :tabs="tabs" :index="index"></my-tabs>
    <my-section title="客户服务" id="contact0" style="display: none;">
      <ul class="cust-service">
        <li v-for="(item,i) in service" :key="i">
          <img :src="item.icon" />
          <dl>
            <dt>{{ item.name }}</dt>
            <dd>{{ item.intro }}</dd>
          </dl>
        </li>
      </ul>
    </my-section>
   <side-code style="display: none;">
      <p>需要客户服务？<br>微信扫码获得<br>小哥哥技术支持~</p>
    </side-code>
    <my-section title="联系我们" id="contact1">
      <div class="contact-box">
        <div class="contact-info">
          <div class="tit">
            <span>深圳市优讯信息技术有限公司</span>
            <i>SHENZHEN UXUN INFORMATION TECHNOLOGY CO.,LTD</i>
          </div>
          <p>
            <img src="~@/assets/images/contact/icon-tel.png" />
            <span>电话：0755-82533629<i>（工作时间：周一至周五，09:00-18:00）</i></span>
          </p>
          <p>
            <img src="~@/assets/images/contact/icon-fix.png" />
            <span>传真：0755-33501096</span>
          </p>
          <p>
            <img src="~@/assets/images/contact/icon-card.png" />
            <span>邮编：518049</span>
          </p>
          <p>
            <img src="~@/assets/images/contact/icon-addr.png" />
            <span>地址：广东省深圳市福田区国际金融科技城1701-1704</span>
          </p>
        </div>
        <div class="contact-code">
          <p>扫一扫添加优讯官方微信</p>
          <img src="~@/assets/images/contact/code.jpg" />
     <!--     <a href="http://api.map.baidu.com/marker?location=22.575537,114.070803&title=公司位置&content=深圳市优讯信息技术有限公司&coord_type=bd09ll&output=html&src=webapp.baidu.openAPIdemo" target="_blank">
            <img src="~@/assets/images/contact/icon-map.png" />
            <span>点击查看百度地图</span>
          </a> -->
        </div>
      </div>
    </my-section>

  </div>
</template>

<script>
import store from "@/store"
import { banner,myTabs,mySection, sideCode } from '@/components'
import bannerPath from '@/assets/images/contact/banner.jpg'
export default {
  components: {
    banner,myTabs,
    mySection,sideCode
  },
  data () {
    return {
      bannerPath,
      tabs: ['联系我们'],
      service: [
        {
          name: '服务公告',
          intro: '第一时间发布动态、系统调整、升级测试等公共信息及优讯重要服务信息。',
          icon: require('@/assets/images/contact/ser-01.jpg')
        },
        {
          name: '技术支持',
          intro: '通过在线资源自助排查问题，进入专项服务通道提出申请，可实时跟踪需求进展，自主管理配置信息。',
          icon: require('@/assets/images/contact/ser-02.jpg')
        },
        {
          name: '下载中心',
          intro: '提供各项安装升级程序、系统语音文件、维护操作手册、服务流程指南等资源下载。',
          icon: require('@/assets/images/contact/ser-03.jpg')
        }
      ]
    }
  },
  computed:{
    index(){
      return store.state.contactTab
    }
  },
  mounted () {
    
  },
  methods: {
    // tabchange(e){
    //   let section = document.querySelector("#contact" + e)
    //   if(section){
    //     window.scroll(0, section.offsetTop)
    //   }
    // }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  @import '@/assets/scss/_mixin.scss';
  .cust-service{
    @extend %c;
    width: 1200px;
    margin: 0 auto 40px;
    li{
      float: left;
      width: 380px;
      height: 340px;
      cursor: pointer;
      box-shadow: 0 0 15px rgba(0,0,0,0.04);
      border-radius: 10px;
      padding-top: 20px;
      &+li{
        margin-left: 30px;
      }
      &:hover{
        img{
          width: 150px
        }
        dd{
          height: 78px;
          opacity: 1;
          color: $color-primary * 3;
        }
      }
    }
    img{
      @extend %transition;
      display: block;
      width: 180px;
      margin: 0 auto 20px;
    }
    dt{
      font-size: 24px;
      line-height: 1;
      text-align: center;
      margin-bottom: 15px;
    }
    dd{
      @extend %transition;
      font-size: 16px;
      color: #fff;
      text-align: center;
      line-height: 26px;
      height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0 30px;
    }
  }
  .contact-box{
    @extend %c;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
  }
  .contact-info{
    @extend %fl;
    .tit{
      margin-bottom: 20px;
      span{
        display: block;
        font-size: 24px;
        color: #2d86ed;
        line-height: 28px;
      }
      i{
        display: block;
        font-size: 12px;
        color: $color-primary * 2;
        line-height: 24px;
      }
    }
    p{
      font-size: 0;
      height: 24px;
      &+p{
        margin-top: 15px;
      }
      img{
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 15px;
      }
      span{
        display: inline-block;
        @include lineHeight(24px);
        font-size: 16px;
        vertical-align: middle;
      }
      i{
        color: $color-primary * 3;
      }
    }
  }
  .contact-code{
    float: right;
    margin-right: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    >img{
      display: block;
      width: 140px;
      margin: 10px auto 20px;
      border: 7px solid #eff7fe;
    }
    a{
      display: block;
      font-size: 0;
      img{
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        vertical-align: middle;
      }
      span{
        display: inline-block;
        line-height: 40px;
        font-size: 16px;
        font-weight: bold;
        color: #2d86ed;
        vertical-align: middle;
      }
    }
  }

  @media screen and (max-width:1200px) {
    .cust-service{
      width: auto;
      padding: 0 px2rem(15px);
      margin: 0 auto px2rem(15px);
      li{
        display: flex;
        align-items: center;
        float: none;
        width: auto;
        height: auto;
        box-shadow: 0 0 px2rem(7.5px) rgba(0,0,0,0.04);
        border-radius: px2rem(5px);
        padding: px2rem(15px) 0;
        &+li{
          margin: px2rem(15px) 0 0 0;
        }
        &:hover{
          img{
            width: px2rem(80px);
          }
          dd{
            height: auto;
            opacity: 1;
          }
        }
      }
      img{
        display: block;
        width: px2rem(80px);
        margin: 0 0 0 0;
      }
      dl{
        flex: 1;
      }
      dt{
        font-size: px2rem(15px);
        text-align: justify;
        margin-bottom: px2rem(7.5px);
      }
      dd{
        text-align: justify;
        font-size: px2rem(13px);
        line-height: px2rem(20px);
        height: auto;
        opacity: 1;
        padding: 0 px2rem(15px) 0 0;
        color: $color-primary * 3;
      }
    }
    .contact-box{
      width: auto;
      padding: 0 px2rem(20px) px2rem(30px);
    }
    .contact-info{
      float: none;
      .tit{
        margin-bottom: px2rem(20px);
        span{
          font-size: px2rem(16px);
          line-height: px2rem(24px);
        }
        i{
          font-size: px2rem(12px);
          line-height: px2rem(20px);
        }
      }
      p{
        height: auto;
        display: flex;
        &+p{
          margin-top: px2rem(7.5px);
        }
        img{
          display: block;
          width: px2rem(18px);
          height: px2rem(18px);
          margin-right: px2rem(10px);
        }
        span{
          display: block;
          height: auto;
          line-height: px2rem(18px);
          font-size: px2rem(12px);
        }
      }
    }
    .contact-code{
      display: flex;
      flex-direction: column-reverse;
      float: none;
      margin: 0;
      font-size: px2rem(14px);
      line-height: px2rem(20px);
      >img{
        width: px2rem(100px);
        margin: px2rem(10px) auto px2rem(15px);
        border: px2rem(4px) solid #eff7fe;
      }
      a{
        display: flex;
        align-items: center;
        text-align: left;
        margin: px2rem(8px) 0 px2rem(30px);
        img{
          display: block;
          width: px2rem(18px);
          height: px2rem(18px);
          margin-right: px2rem(10px);
        }
        span{
          display: block;
          line-height: px2rem(18px);
          font-size: px2rem(12px);
        }
      }
      p{
        font-size: px2rem(12px);
        color: $color-primary * 2;
      }
    }
  }
</style>
